import m from "mithril";

const nextId = (prefix = ""): string => {
  return (prefix || "") + Math.random().toString(36).slice(2, 11);
};

const InputLabel: m.Component<{ label?: string; id?: string }> = {
  view({ attrs: { label, id } }) {
    return m(
      "label",
      {
        for: id,
        class:
          "relative block text-sm antialiased max-w-full pointer-events-auto break-words truncate w-72 text-white group-focus-within:text-white-500",
      },
      m("span", label)
    );
  },
};

const InputBorder: m.Component = {
  view() {
    return m("div", {
      class:
        "w-full h-full absolute bg-transparent pointer-events-none flex flex-row items-stretch z-1 rounded border border-slate-500 group-focus-within:border-slate-500",
    });
  },
};

interface TextFieldAttrs {
  label?: string;
  autocomplete?: string;
  placeholder?: string;
  type?: string;
  value?: any;
  focus?: boolean;
  onValue?: (value: any) => void;
}

interface TextFieldState {
  id: string;
}

export const TextField: m.Component<TextFieldAttrs, TextFieldState> = {
  oninit({ state }) {
    state.id = nextId("text-input-");
  },
  view({ state: { id }, attrs: { label, autocomplete = "off", type = "text", focus, value, onValue, placeholder } }) {
    return m(
      "div",
      m(InputLabel, {
        label,
        id,
      }),
      m(
        "div",
        {
          class:
            "w-72 focus-within:shadow-input-focus h-10 bg-white text-sm flex flex-row rounded items-center relative max-w-full peer group pointer-events-auto",
        },
        m(InputBorder),
        m("input", {
          type,
          tabindex: "0",
          autocomplete,
          id,
          placeholder,
          oncreate: (vnode) => {
            const dom = vnode.dom as HTMLInputElement;

            if (focus && typeof dom.focus === "function") {
              dom.focus();
            }
          },
          oninput: (e: InputEvent) => onValue?.((e.target as HTMLInputElement).value),
          value,
          class:
            "flex-1 focus:outline-none appearance-none bg-transparent peer min-w-12 p-2 ml-1 min-h-4 cursor-text text-slate-700 placeholder-slate-500",
        })
      )
    );
  },
};

export const EmailField: m.Component<TextFieldAttrs> = {
  view({ attrs }) {
    return m(TextField, { type: "email", ...attrs });
  },
};

export const PasswordField: m.Component<TextFieldAttrs> = {
  view({ attrs }) {
    return m(TextField, { type: "password", ...attrs });
  },
};
