import { clsx } from "clsx";
import m from "mithril";

interface Attrs {
  url?: string;
  onclick?: () => void;
  target?: string;
  rel?: string;
  download?: string;
}

export const TextLink: m.Component<Attrs> = {
  view({ attrs: { url, target, rel, onclick, download }, children }) {
    return m(
      "a",
      {
        target,
        class: clsx(
          "hover:underline",
          "cursor-pointer",
          "text-sky-600",
          "hover:text-sky-700",
          "focus:text-sky-700",
          "focus:ring-2",
          "focus:ring-sky-500",
          "focus:ring-offset-2",
          "focus:outline-none",
          "rounded",
          "block"
        ),
        tabindex: 0,
        href: url,
        rel,
        onclick,
        download,
      },
      children
    );
  },
};
