import { clsx } from "clsx";
import m from "mithril";

export const BlueBackground: m.Component = {
  view({ children }) {
    return m(
      "div",
      {
        class: clsx(
          "fixed",
          "left-0",
          "top-0",
          "w-full",
          "h-full",
          "min-h-72",
          "bg-gradient-to-b",
          "from-[rgba(0,132,199,1)]",
          "to-[rgba(0,99,149,1)]",
          "py-10",
          "text-white"
        ),
      },
      children
    );
  },
};
