import m from "mithril";
import { AuthLoginResponse } from "~/types";

export const postVerifyRecoveryCode = (recoveryCode: string, rememberMe: boolean): Promise<AuthLoginResponse> => {
  return m.request({
    method: "POST",
    url: "/auth/recovery",
    body: { recoveryCode, rememberMe },
  });
};
