import m from "mithril";
import arrowleft from "../assets/icons/arrowleft.svg";
import phone from "../assets/icons/phone.svg";

const of = (svg: string): m.Component => {
  return {
    oncreate({ dom }) {
      dom.innerHTML = svg;
    },
    view() {
      return m("div.fill-current.svg-icon.flex.justify-center");
    },
  };
};

export const ArrowLeft = of(arrowleft);
export const PhoneIcon = of(phone);
