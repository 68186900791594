import { clsx } from "clsx";
import m from "mithril";

interface CheckboxAttrs {
  class?: string;
  value?: boolean;
  label?: m.Child;
  onValue: (value: boolean) => void;
}

export const Checkbox: m.Component<CheckboxAttrs> = {
  view({ attrs: { class: className, value, onValue, label } }) {
    return m(
      "label",
      {
        class: clsx(className, "flex", "items-center"),
      },
      m("input", {
        checked: value,
        type: "checkbox",
        class: clsx(
          "form-checkbox",
          "focus:ring-primary-500",
          "h-4",
          "w-4",
          "text-primary-600",
          "border-slate-300",
          "rounded"
        ),
        onkeydown: (e: KeyboardEvent) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            onValue(!value);
          }
        },
        oninput: (e: InputEvent) => {
          e.preventDefault();

          onValue(!value);
        },
      }),
      m(
        "div",
        {
          class: clsx("flex-1", "ml-3", "text-sm", "flex-1", "font-medium", "break-words", "overflow-hidden"),
        },
        label
      )
    );
  },
};
