import { clsx } from "clsx";
import m from "mithril";
import { fetchSettings } from "~/api/fetchSettings";
import { AuthMethods } from "~/components/AuthMethods";
import { Bubbles } from "~/components/Bubbles";
import { Copyright } from "~/components/Copyright";
import { LoadingAnimationCenter } from "~/components/LoadingAnimation";
import { LoginErrorLabel } from "~/components/LoginErrorLabel";
import { LogoWithText } from "~/components/LogoWithText";
import { OptionalBackButton } from "~/components/OptionalBackButton";
import { UsernameEntry } from "~/components/UsernameEntry";
import { GoogleLoginButton } from "~/gsignin/GoogleLoginButton";
import { getGCID, isGoogleSigninEnabled } from "./globalSettings";
import { Browser } from "./utils/Browser";

let username = "";
let methods: string[] = [];
let opts: Record<string, string> = {};
let authState: "username" | "auth" = "username";
let loading = false;
let hasError = false;

const setLoading = (value: boolean) => {
  loading = value;
};

const isLoading = () => {
  return loading;
};

const setHasError = (value: boolean) => {
  hasError = value;
};

const reset = () => {
  setLoading(false);
  setHasError(false);
  opts = {};
  username = "";
  authState = "username";
  methods = [];
};

const onAfterUsernameSet = async (onGotoAuth: () => void) => {
  // We meet the issue many times that `/auth/settings` is triggered multiple times in a very short duration.
  // Refer to https://github.com/visdomtech/work/issues/271, so FE do this small protection here.
  if (isLoading()) {
    return;
  }

  setLoading(true);
  const { data } = await fetchSettings(username);

  methods = data.methods;
  opts = data.opts;
  onGotoAuth();
  setLoading(false);
};

const LoginContents: m.Component<{ authState: string; onGotoAuth: () => void }> = {
  oninit() {
    const previousUsername = Browser.localStorageGetItem("username");

    if (previousUsername) {
      username = previousUsername;
    }
  },
  view({ attrs: { authState, onGotoAuth } }) {
    if (loading) {
      return m(LoadingAnimationCenter);
    }

    switch (authState) {
      case "username":
        return m(
          "div",
          isGoogleSigninEnabled() &&
            m("div", m(GoogleLoginButton, { username: "", gcid: getGCID() }), m("div.my-4.text-center", "- OR  -")),
          m(LoginErrorLabel, { error: hasError }),
          m(UsernameEntry, {
            username,
            setLoading,
            setHasError,
            onSetUsername: async (newUsername) => {
              username = newUsername;
              Browser.localStorageSetItem("username", username);

              await onAfterUsernameSet(onGotoAuth);
            },
          })
        );
      case "auth":
        return m(AuthMethods, { methods, username, setLoading, opts });
    }
  },
};

export const LoginPage: m.Component = {
  oninit() {
    // TODO refactor so we don't need to have global state.
    // reset global state, otherwise tests will fail.
    reset();
  },
  view() {
    return m(
      "div",
      {
        class: clsx(
          "fixed",
          "left-0",
          "top-0",
          "w-full",
          "h-full",
          "min-h-72",
          "bg-gradient-to-b",
          "from-[rgba(0,132,199,1)]",
          "to-[rgba(0,99,149,1)]"
        ),
      },
      m(Bubbles),
      m(
        "div.mt-36.flex.flex-col.items-center.space-y-4.text-white.relative",
        m(LogoWithText),
        m(LoginContents, {
          authState,
          onGotoAuth: () => {
            authState = "auth";
          },
        }),
        m(OptionalBackButton, { state: authState, reset }),
        m("div.text-white", m(Copyright))
      )
    );
  },
};
