import { clsx } from "clsx";
import m from "mithril";
import { postVerifyCode } from "./api/postVerifyCode";
import { BlueBackground } from "./components/BlueBackground";
import { Checkbox } from "./components/Checkbox";
import { Copyright } from "./components/Copyright";
import { TinyLoadingAnimationCenter } from "./components/LoadingAnimation";
import { LogoSection } from "./components/LogoSection";
import { PrimaryButton } from "./components/PrimaryButton";
import { PhoneIcon } from "./components/SvgIcons";
import { Text } from "./components/Text";
import { TextField } from "./components/TextField";
import { TextLink } from "./components/TextLink";
import { onLoginSuccess } from "./utils/onLoginSuccess";
import { withRedirectUri } from "./utils/withRedirectUri";

let code = "";
let isLoading = false;
let error = "";
let rememberMe = false;

const doVerify = async () => {
  try {
    isLoading = true;
    error = "";
    const result = await postVerifyCode(code, rememberMe);

    code = "";
    onLoginSuccess(result);
  } catch (e: any) {
    error = "response" in e && "status" in e.response ? e.response.status : "Invalid credentials";
    // eslint-disable-next-line no-console
    console.log(e);
    isLoading = false;
  }
};

export const MFAAuthPage: m.Component = {
  view() {
    return m(
      BlueBackground,
      m(LogoSection),
      m(
        "div.w-80.space-y-4.mx-auto",
        m("div.text-center", m(Text, { variant: "title" }, "Two-factor authentication")),
        m(
          "div.p-4.space-y-3",
          m("div.mx-auto.w-6.h-6", m(PhoneIcon)),
          m("div.text-center", m(Text, { variant: "subtitle" }, "Authentication code")),
          m(TextField, {
            value: code,
            focus: true,
            placeholder: "XXXXXX",
            autocomplete: "one-time-code",
            onValue(value) {
              code = value;
            },
          }),
          error && m(Text, { variant: "error" }, error),
          m(Checkbox, {
            label: "remember me on this device",
            value: rememberMe,
            onValue(value) {
              rememberMe = value;
            },
          }),
          m(
            PrimaryButton,
            {
              disabled: !code || isLoading,
              onclick() {
                doVerify();
              },
            },
            isLoading ? m(TinyLoadingAnimationCenter) : "Verify"
          ),
          m(
            Text,
            "Open your two-factor authenticator (TOTP) app or browser extension to view your authentication code."
          )
        ),
        m(
          "div.p-4.space-y-3",
          m(Text, { variant: "label" }, "Having problems?"),
          m(
            "ul.pl-4",
            m(
              "li.list-disc",
              m(
                TextLink,
                {
                  url: withRedirectUri("/auth/recovery"),
                },
                m("span", { class: clsx("text-sky-300", "hover:text-sky-400") }, "Use a recovery code")
              )
            )
          )
        )
      ),
      m("div.mt-6", m(Copyright))
    );
  },
};
