const APP_REDIRECT_URI_QUERY_KEY = "redirect_uri";

export const withRedirectUri = (route: string) => {
  const searchParams = new URLSearchParams(location.search);
  const redirectUri = searchParams.get(APP_REDIRECT_URI_QUERY_KEY);

  if (!redirectUri) {
    return route;
  }

  return `${route}?${APP_REDIRECT_URI_QUERY_KEY}=${redirectUri}`;
};
