import m from "mithril";
import { checkAlreadyAuthenticated } from "~/utils/checkAlreadyAuthenticated";
import { initGlobalSettings } from "./globalSettings";
import { LoginPage } from "./LoginPage";
import { MFAAuthPage } from "./MFAAuthPage";
import { MFARecoveryPage } from "./MFARecoveryPage";

checkAlreadyAuthenticated();

initGlobalSettings();

const routes: Record<string, m.Component> = {
  "/auth/mfa": MFAAuthPage,
  "/auth/recovery": MFARecoveryPage,
  "/": LoginPage,
};

const SimpleRouter: m.Component = {
  view() {
    const pathname = location.pathname;
    const RouteComponent = routes[pathname];

    if (!RouteComponent) {
      return m(LoginPage);
    }

    return m(RouteComponent);
  },
};

const appElement = document.getElementById("app");

if (appElement) {
  m.route(appElement, "/", {
    "/": SimpleRouter,
  });
}

const isAuthRoute = (pathname: string) => {
  return pathname.startsWith("/auth");
}

const pathname = location.pathname;

if(!isAuthRoute(pathname)) {

  if (!localStorage.getItem("requestedPath")) { 
    localStorage.setItem("requestedPath", pathname);
  }
}
