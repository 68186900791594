import m from "mithril";

type Variant = "title" | "subtitle" | "label" | "text" | "error";

const variantStyles: Record<Variant, { selector: string; classname: string }> = {
  title: { selector: "h1", classname: "text-2xl font-normal" },
  subtitle: { selector: "h2", classname: "text-lg font-normal" },
  label: { selector: "p", classname: "text-sm font-bold" },
  text: { selector: "p", classname: "text-sm" },
  error: { selector: "p", classname: "text-sm text-red-700" },
};

interface Attrs {
  variant?: Variant;
}

export const Text: m.Component<Attrs> = {
  view({ attrs: { variant = "text" }, children }) {
    const { selector, classname } = variantStyles[variant];

    return m(selector, { class: classname }, children);
  },
};
